import onmount from "onmount";

// Permitiremos cualquier data attribute en el contenido de un popover
let popoverDefaultWhiteList = $.fn.popover.Constructor.Default.whiteList;
const dataRegex = /^data-[\w-]+/;
popoverDefaultWhiteList["*"].push(dataRegex);

/*
 * Activa todos los popover
 * requiere bootstrap y jquery
 */

onmount('[data-popover], [data-toggle="popover"]', function () {
  $(this).popover({ html: true });
});

$("body").on("buk:load-content", ".popover", function () {
  $(this).data("bs.popover").update();
});
