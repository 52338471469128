const $ = (jQuery = require("jquery"));
const onmount = require("onmount");
require("bootstrap-datepicker");
require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.es.min");

$.extend($.fn.datepicker.defaults, {
  language: "es",
  orientation: "auto",
  todayHighlight: true,
  toggleActive: true,
  autoclose: true,
  zIndexOffset: 1040,
});

$.fn.datepicker.dates.es.format = "dd-mm-yyyy";

// Datepicker jquery plugin inicia automaticamente sobre [data-provide=datepicker]
onmount(
  "[data-provide=datepicker]",
  function () {},
  function () {
    $(this).datepicker("destroy");
  }
);
