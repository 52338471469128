const $ = require("jquery");
const onmount = require("onmount");
require("select2");
require("select2/dist/js/i18n/es");

module.exports = function (select2_opts) {
  if ($.fn.modal) {
    $.fn.modal.Constructor.prototype._enforceFocus = function () {};
  }
  onmount(
    '[data-provide="select2"]',
    function () {
      const $select = $(this);
      if ($select.hasClass("form-control")) {
        $select.css("width", "100%");
      }
      $select.select2(select2_opts);
    },
    function () {
      $(this).select2("destroy");
    }
  );
};
