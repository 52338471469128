/** @file TODO: documentar */
import { defaults } from "lodash";

const ErrorTemplate = `
<div class="alert alert-danger">
Hubo un error al cargar la información. Haz click <a href="javascript:void(0)">aquí</a> para reintentar.
</div>
`;

/** @param {Element} element */
function handleLoadError(element, retry) {
  element.innerHTML = ErrorTemplate;
  element.getElementsByTagName("a")[0].addEventListener("click", () => {
    retry();
    return false;
  });
}

/**
 *
 * @param {Element} element
 * @param {String} content
 */
function handleSuccess(element, content) {
  element.innerHTML = content;
  // Gatillamos el evento load-content para onmount y demases
  const event = document.createEvent("CustomEvent");
  event.initCustomEvent("buk:load-content", true, false, undefined);
  element.dispatchEvent(event);
}

/** @interface
 */
const LoadOptions = {
  /** @property {String} method El método a utilizar. Puede ser GET o POST */
  method: "GET",
};

/** Obtiene una página por ajax y carga el contenido en el elemento especificado
 *
 *  @param {Element} element El elemento donde se cargarán los datos
 *  @param {String} url La url a cargar
 *  @param {LoadOptions} options Opciones a cargar
 *
 *  @returns {void}
 */
async function load(element, url, options = {}) {
  const origHtml = element.innerHTML;
  const captured = element;
  const retry = () => {
    captured.innerHTML = origHtml;
    load(element, url, options);
  };
  options = defaults(LoadOptions, options);
  const res = $.ajax({
    url: url,
    method: options.method,
  });
  res
    .done((data) => {
      return handleSuccess(captured, data);
    })
    .fail(() => {
      return handleLoadError(captured, retry);
    });
}

export default load;
