const onmount = require('onmount');
const $ = require('jquery');
import ModalBuilder from '../lib/modal-builder';

$(document).on('galapagos:load-modal', onmount);

onmount('[data-modal-link]', function(){
  const href = $(this).attr('href');
  const title = $(this).data('title');
  const target = $(this).data('modal-link-target');
  const size = $(this).data('modal-link-size');
  const modalBuilder = new ModalBuilder(target);
  modalBuilder.setSize(size);
  $(this).click(function(e){
    e.preventDefault();
    modalBuilder.loadFromRemote(href, null, title);
    modalBuilder.show();
  });
});
