/*
  Define el comportamiento del componente de firma
  Para utilizarlo se necesitan dos elementos.
    Un input tipo archivo donde se dejarán los datos de la firma cómo si el usuario subiera el dibujo
    Un canvas donde se dibujará la firma

  El input debe indicar mediante la propiedad data-signature un selector hacia el canvas de dibujo.

*/
import $ from 'jquery';
import onmount from "onmount";
import SignaturePad from "signature_pad";

async function updateInputData(input, signaturePad){
  const signatureDataUrl = signaturePad.toDataURL();
  const response = await fetch(signatureDataUrl);
  const signatureBlob = await response.blob();
  const signatureFile = new File([signatureBlob], 'signature.png', {type: 'image/png'});
  const dt = new DataTransfer();
  dt.items.add(signatureFile);
  input.files = dt.files;
}

function disableLabelClick(input){
  let id = input.getAttribute('id');
  let label = document.querySelector(`label[for="${id}"]`);
  label?.addEventListener('click', (e) => e.preventDefault());
}

function setupSignaturePad(input, canvas){
  input.classList.add('d-none');
  disableLabelClick(input);
  const signaturePad = new SignaturePad(canvas);
  signaturePad.addEventListener("endStroke", function(){
    updateInputData(input, signaturePad);
  });
  let resizeSignatureCallback = resizeSignature(signaturePad, canvas);
  window.addEventListener("resize", resizeSignatureCallback);
  resizeSignatureCallback();
}

function resizeSignature(signaturePad, canvas) {
  const ratio =  Math.max(window.devicePixelRatio || 1, 1);
  return function(){
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    signaturePad.clear();
  }
}

onmount("[data-signature]", function(){
  const signatureSelector = $(this).data("signature");
  let $canvases = $(signatureSelector);
  $canvases.each((_index, canvas) => setupSignaturePad(this, canvas));
});
