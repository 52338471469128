const $ = require('jquery');
const onmount = require('onmount');

let openClass = 'pending-tasks-opened';
let closeClass = 'pending-tasks-closed';
let workingClass = 'pending-tasks-working';
let pausedClass = 'pending-tasks-paused';

class PendingTaskToggler{
  constructor($container){
    this.$toggle = $container.find('.pending-tasks-toggle');
    this.$pendingTasks = $container.find('.pending-tasks')
  }
  init(){
    this.$toggle.click(() => this.togglePendingTasks());
  }
  togglePendingTasks(){
    if(this.$pendingTasks.is(`.${openClass}`)){
      this.openPendingTasks();
    }else{
      this.closePendingTasks();
    }
  }
  openPendingTasks(){
    this.$pendingTasks.removeClass(openClass).addClass(closeClass);
  }

  closePendingTasks(){
    this.$pendingTasks.removeClass(closeClass).addClass(openClass);
  }
  complete(){
    this.$pendingTasks.removeClass(workingClass).addClass(pausedClass);
  }
}

class PendingTasksMonitor{
  constructor($pendingTasksList) {
    this.ended = false;
    this.pendingTasksList = $pendingTasksList;
    this.url = this.pendingTasksList.data('statusUrl');
    this.completedCallbacks = [];
  }

  start(){
    this.checkStatus();
  }

  checkStatus(){
    $.ajax({
      url: this.url,
      error: () => setTimeout(() => { this.checkStatus(); }, 10000) // si falla se vuelve a llamar en 10 segundos.
    }).done(data => this.updateTasks(data));
  }

  updateTasks(tasks){
    tasks.forEach(task => {
      let $taskContainer = this.findTaskContainer(task);
      statusUpdater = new PendingTaskUpdater($taskContainer);
      statusUpdater.updateTask(task);
    });
    failedOrCompletedTasks = tasks.filter(task => task.status == 'completed' || task.status == 'failed');
    if(failedOrCompletedTasks.length == tasks.length){
      this.completed();
    }
    if (!this.ended) {
      setTimeout(() => { this.checkStatus(); }, 3000); // se vuelve a llamar en 3 segundos a checkStatus mientras hayan tareas pendientes no completadas.
    }
  }

  findTaskContainer(task) {
    return this.pendingTasksList.find(`#pending-task-${task.id}`)
  }

  stop(){
    clearInterval(this.interval);
    this.interval = null;
  }
  onCompleted(fn){
    this.completedCallbacks.push(fn);
  }
  completed(){
    this.completedCallbacks.forEach( fn => fn() );
    this.ended = true
  }
}

class PendingTaskUpdater{
  constructor($pendingTask){
    this.$status = $pendingTask.find('.pending-task-status');
    this.$downloadLink = $pendingTask.find('.pending-task-download');
  }
  updateTask(task){
    this.updateTaskStatus(task.status);
    if(task.status == 'completed'){
      this.updateTaskDownloadLink(task);
    }
  }
  updateTaskStatus(status){
    if(status == this.currentStatus){
      return;
    }
    this.currentStatus = status;
    switch (status) {
      case 'pending':
        this.$status.addClass(`${pausedClass} text-secondary`);
        break;
      case 'working':
        this.$status.removeClass(`${pausedClass} text-secondary`);
        this.$status.addClass(`${workingClass} text-primary`);
        break;
      case 'failed':
        this.$status.addClass(`${pausedClass} text-danger`);
        break;
      case 'completed':
        this.$status.addClass(`${pausedClass} text-success`);
        break;
    }
  }
  updateTaskDownloadLink(task){
    if(task.attachment){
      this.$downloadLink.removeClass('d-none');
    }
  }
}


onmount('.pending-tasks-container', function(){
  let $container = $(this);
  let $list = $container.find('.pending-tasks-list');
  let pendingTasksToggler = new PendingTaskToggler($container);
  let monitor = new PendingTasksMonitor($list);
  monitor.onCompleted( () => {
    pendingTasksToggler.complete();
    monitor.stop();
  });

  monitor.start();

  pendingTasksToggler.init();
});
