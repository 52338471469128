/*
  En un formulario con data-provide=accordion_form, pone un icono de valido o invalido
  a los headers del accordion
  Cuando un input lanza invalid verifica que todos los inputs que pertenezcan al mismo
  item del accordion sean validos
*/

const onmount = require('onmount');
const $ = require('jquery');
const _ = require('lodash');

// Retorna el elemento .card-header de un input
function inputHeader(input){
  return $(input).closest('.card').find('.card-header');
}

//Retorna el elemento .collapse de un input
function inputCollapse(input){
  return $(input).closest('.collapse');
}

// Agrega un icono para marcar el header como no valido
function invalidateHeader(header){
  if(!header.is('.header-invalid')){
    const invalidIcon = `
      <i class="validation-icon fas fa-2x fa-exclamation-circle text-danger"></i>
    `;
    header.removeClass('.header-valid').addClass('.header-invalid');
    header.find('.validation-icon').remove();
    header.append(invalidIcon);
  }
}

// Agrega un icono para marcar el header como valido
function validateHeader(header){
  if(!header.is('.header-valid')){
    const validIcon = `
      <i class="validation-icon fas fa-2x fa-check text-success"></i>
    `;
    header.removeClass('.header-invalid').addClass('.header-valid');
    header.find('.validation-icon').remove();
    header.append(validIcon);
  }
}
// Muestra el collapse dado
function focusCollapse(collapse){
  collapse.collapse('show');
}

const INPUT_SELECTOR = ":input:not([type=hidden]):not(button):not([type=button])";

// Valida un collapse, y pone el icono correspondiente al header
// si todos los input del collapse son validos
function validateCollapse(header, collapse){
  let $inputs = collapse.find(INPUT_SELECTOR);
  let valid_inputs = $inputs.filter(function(){
    return this.checkValidity() && !$(this).is('.is-invalid');
  });
  if($inputs.length == valid_inputs.length){
    validateHeader(header)
  }else{
    invalidateHeader(header);
  }
}

onmount("[data-provide='accordion_form']", function(){
  const $form = $(this);
  // Cada header se hace flex para agregar el icono a un lado
  const $headers = $form.find('.card-header').addClass('d-flex align-items-center justify-content-between');
  const $collapses = $form.find('.collapse');

  // Una funcion que se ejecutara luego de 500ms con los argumentos de la primera llamada
  // para mostrar el collapse del primer input invalid
  const collapseFocus = _.debounce(focusCollapse, 1000, {
    'leading': true,
    'trailing': false
  });
  // Cuando un input lance invalid, se agrega el icono al header
  $form.find(INPUT_SELECTOR).on('invalid', function(){
    invalidateHeader(inputHeader(this));
    collapseFocus(inputCollapse(this));
  });
  // Cada input change validara su collapse correspondiente
  $form.find(INPUT_SELECTOR).on('change', function(){
    let $collapse = inputCollapse(this);
    let $header = inputHeader(this);
    validateCollapse($header, $collapse);
  });
  // Se validan los collapses al subir el formulario
  $form.find('[type=submit]').on('click', function(){
    $collapses.each(function(i){
      validateCollapse($headers.filter(":nth("+i+")"), $(this));
    });
  });
  // Se validan los collapses si existe al menos input.is-invalid
  if($form.find(':input.is-invalid').length){
    $collapses.each(function(i){
      validateCollapse($headers.filter(":nth("+i+")"), $(this));
    });
  }
});
