import {initSelect, initTable} from './table'
import onmount from 'onmount';

onmount('[data-provide="datatable"]', function () {
  const $table = $(this);
  initTable($table, onmount);
}, function () {
  $(this).DataTable().destroy();
});


onmount('.table-component [data-provide="datatable"]:not([data-select="false"])', function(){
  const $table = $(this);
  initSelect($table);
});
