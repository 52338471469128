import onmount from 'onmount';

onmount('[data-close-asidebar=true], .aside-base .close-btn, .aside-mask', function(){
  this.addEventListener('click', function(e){
    e.preventDefault();
    const asideBase = this.closest('.aside-base');
    const asideContainer = asideBase.querySelector('.aside-container');
    asideContainer.classList.remove('aside-fade-enter-active');
    asideContainer.classList.add('aside-fade-leave-active');
    asideContainer.classList.add('aside-fade-leave-to');
    setTimeout(function(){ asideBase.remove(); }, 150);
  });
});
