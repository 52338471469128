require('datatables.net-bs4');
require('datatables.net-select-bs4');
require('datatables.net-buttons/js/buttons.html5.js')
require('datatables.net-buttons-bs4');
require('./datatables/wait-datatable');
const _ = require('lodash');
const es = require('./datatables/lang/es');
const $ = require('jquery');

function tableComponentSelectFunction($select, $server_side, $selected_rows_count, compareFn) {
  return function (e, dt, type, indexes) {
    const ids = dt.rows(indexes).ids();
    const to_select = compareFn($select.val(), ids);
    $select.val(to_select);

    if ( $server_side ) {
      $selected_rows_count.text(to_select.length);
    }
  }
}

async function tableComponentSelectSetup($table, $select, $server_side, $table_ids, $selected_rows_count) {

  await $table.waitDataTable();
  if($table.data('table-component-select')){
    return;
  }

  $table.data('table-component-select', true);
  const api = $table.DataTable();
  const tableSelectAllCell = $table.find('thead .select-all-cell');
  const selectAllCheckbox = $('<input/>').attr('type', 'checkbox');

  selectAllCheckbox.on('click', function(){
    if ( $server_side ) {
      let to_select = this.checked ? _.union($select.val(), $table_ids) : [];
      $select.val(to_select);
    }
    this.checked ? api.rows().select() : api.rows().deselect();
  });

  tableSelectAllCell.html(selectAllCheckbox);

  api.on('select', tableComponentSelectFunction($select, $server_side, $selected_rows_count, _.union));
  api.on('deselect', tableComponentSelectFunction($select, $server_side, $selected_rows_count, _.difference));

  // Cuando se dibuja la tabla seleccionamos las previamente seleccionadas
  api.on( 'draw.dt', function () {
    const to_select = $select.val().map(function(id) {
      return "#" + id;
    });
    api.rows(to_select).select();
  });

  // Seleccionar las filas que vienen desde el input
  let selectedIds = $select.val().map(function(id){
    return "#" + id;
  });
  api.rows(selectedIds).select();
}
export function initSelect($table){
  let $table_ids, $selected_rows_count;
  const $server_side = $table.attr('data-server-side') == "true";
  const $select = $table.closest('.table-component').find('.rows-select-input');

  if ($server_side && $table.attr('data-select') != null) {
    $selected_rows_count = $table.closest('.table-component').find('#selected-rows-count');
    $table_ids = JSON.parse($table.attr('data-ids')).map(String);
  }

  tableComponentSelectSetup($table, $select, $server_side, $table_ids, $selected_rows_count).catch((e) => console.log("Table wasn't charged", e));
}

$.extend($.fn.dataTable.defaults, {
  language: es,
  stateSave: true
});

export function initTable($table, drawCallback) {
  $table.DataTable({
    lengthMenu: [
      [10, 25, 50, -1],
      [10, 25, 50, 'Todas'],
    ],
    buttons: [
      {
        text: '<i class="fas fa-times"></i>',
        action: function (_e, dt, _node, _config) {
          dt.state.clear();
          dt.destroy();
          initTable($table);
        },
        className: 'ml-2 btn-sm btn-light'
      },
      {extend: 'csv',
        text: '<i class="fas fa-download"></i>',
        className: 'btn-sm btn-light'
      }
    ],
    initComplete: function(){
      $(this).parent().addClass('table-responsive');
    },
    layout: {
      topStart: 'pageLength',
      topEnd: ['search', 'buttons'],
    }
  });
  $table.on('draw.dt', drawCallback);
}

(function () {
  function removeAccents(data) {
    if (data.normalize) {
      // Use I18n API if avaiable to split characters and accents, then remove
      // the accents wholesale. Note that we use the original data as well as
      // the new to allow for searching of either form.
      return (
        data + ' ' + data.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      );
    }

    return data;
  }

  let searchType = jQuery.fn.DataTable.ext.type.search;

  searchType.string = function (data) {
    return typeof data === 'string' ? removeAccents(data) : data;
  };

  searchType.html = function (data) {
    return typeof data === 'string'
      ? removeAccents(data.replace(/<.*?>/g, ''))
      : data;
  };
})();
