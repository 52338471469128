/** @file TODO: documentar */
import { isObject, isArray, each } from 'lodash';
const onmount = require('onmount');

//Cuidado al editar este plugin. Si se cambia la manera en que se obtiene el valor
//o se cambia el evento que se escucha para hacer el toggle, areaselect dejaria de considerarse para form-toggle
//Esto porque areaselect emula un input en el div contenedor agregando la propiedad .value con el valor del area
//y lanzando un evento 'change' cuando el valor del areaselect cambia

const toggleFunctions = {
  display: {
    show: function (target) {
      target.show('slow');
    },
    hide: function (target) {
      target.hide('slow');
    },
  },
  visibility: {
    show: function (target) {
      target.addClass('visible').removeClass('invisible');
    },
    hide: function (target) {
      target.addClass('invisible').removeClass('visible');
    },
  },
  'instant-display': {
    show: target => target.show(),
    hide: target => target.hide(),
  }
};

function getToggleOptions(element) {
  return {
    operator: element.data('operator') || '==',
    isDate: element.data('provide') === 'datepicker',
    skipEnable: element.data('skipEnable'),
    groupClass: element.data('groupClass') || '.form-group',
    toggleMethod: toggleFunctions[element.data('toggleMethod')] || toggleFunctions['display'],
    relativeToFiringElement: element.data('relativeToFiringElement') || false,
    relativeFiringElementClass: element.data('relativeFiringElementClass'),
  };
}

function toggleGroup(target, options, state) {
  // Esto retorna el mismo elemento si este es de la misma clase que options.groupClass
  const $target = $(target);
  const group = $target.closest(options.groupClass);
  const method = state ? 'show' : 'hide';
  const finalTarget = group.length ? group : $target;
  let skipSelector = '';
  if (state && options.skipEnable) {
    //Se esta habilitando los input
    skipSelector = ':not(' + options.skipEnable + ')';
  }

  options.toggleMethod[method](finalTarget);
  finalTarget.find(':input' + skipSelector).prop('disabled', !state);
  //Este evento es para indicar que se intenta habilitar/deshabilitar el control
  $target.trigger('form-toggle-enable', state);
  if (state) {
    //Si se esta habilitando, los form toggle interiores verifican si deben cambiar sus inputs asociados
    finalTarget.find('[data-form-toggle]').trigger('form-toggle-check');
  }
}

function getValue($element) {
  if ($element.is(':radio,:checkbox')) {
    return $element.is(':checked') ? $element.val() : '';
  }
  else {
    return $element.val();
  }
}

onmount('[data-form-toggle]', function () {
  const $self = $(this);
  const target = $self.data('formToggle');
  const options = getToggleOptions($self);
  const $target = $(target);
  if ($target.length === 0) {
    throw new Error(`form-toggle target '${target}' does not exist`);
  }
  const toggleValue = $self.data('formToggleValue') || '';
  let previousState = (getValue($self) == toggleValue);

  $self.on('change', doToggle);
  $self.on('form-toggle-check', function () {
    toggleGroup(target, options, getValue($self) != toggleValue);
  });

  function doToggle() {
    const state = (getValue($self) == toggleValue);
    if (state === previousState) return;
    previousState = state;
    // al revés porque se esconde cuando es igual/vacío
    toggleGroup(target, options, !state);
  }
});

onmount('[data-form-switch]', async function () {
  const { default: moment } = await import('moment');
  const $self = $(this);
  const targets = $self.data('formSwitch');
  const options = getToggleOptions($self);
  if (!(isObject(targets) && !isArray(targets))) {
    throw new Error('form-toggle must be an object');
  }

  function compareDate(post, operator, value) {
    post = moment(post, 'DD-MM-YYYY');
    value = moment(value, 'DD-MM-YYYY');
    switch (operator) {
      case '>': return post.isAfter(value);
      case '<': return post.isBefore(value);
      case '>=': return post.isSameOrAfter(value);
      case '<=': return post.isSameOrBefore(value);
      case '==': return post.isSame(value);
      case '!=': return !post.isSame(value);
      default: throw new Error('operator must be one of the list above');
    }
  }

  function compareValues(post, operator, value) {
    switch (operator) {
      case '>': return post > value;
      case '<': return post < value;
      case '>=': return post >= value;
      case '<=': return post <= value;
      case '==': return post == value;
      case '!=': return post != value;
      case '===': return post === value;
      case '!==': return post !== value;
      default: throw new Error('operator must be one of the list above');
    }
  }

  $self.on('change', doToggle);

  function doToggle() {
    const curVal = getValue($self);
    const toggles = {};
    toggles[true] = new Set();
    toggles[false] = new Set();
    const compare = (options.isDate) ? compareDate : compareValues;
    // eslint-disable-next-line lodash/preferred-alias
    each(targets, (selector, toggleValue) => {
      let $target;
      if (options.relativeToFiringElement){
        $target = $self.closest(options.relativeFiringElementClass).find(selector);
      } else {
        $target = $(selector);
      }
      const state = compare(toggleValue, options.operator, curVal);
      $target.each(function () { toggles[state].add(this); });
    });

    toggles[false].forEach(i => {
      // no escondamos si vamos a mostrar
      if (toggles[true].has(i)) {
        return;
      }
      toggleGroup(i, options, false);
    });
    toggles[true].forEach(i => {
      toggleGroup(i, options, true);
    });
  }
});
