/** @file TODO: documentar */
export default class ModalBuilder {

  /**
   * Constructor de modalbuilder
   * @param {string} target - Selector css contenedor del modal que se creara, por defecto 'body'
   */
  constructor(target) {
    // seteamos los defaults
    this.footer = `<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
  </div>`;
    this.size = 'modal-md';
    this.modal = null;
    this.target = target || 'body';
  }

  /**
   * Genera el modal, seteando el title, body, footer y size configurado
   * @return retorna la instancia ModalBuilder
   */
  generateModal() {
    var template =  `
      <div class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Cargando...</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
              <div class="text-center"><div class="spinner-border text-secondary"></div></div>
            </div>
            ${this.footer}
          </div>
        </div>
      </div>
        `;
    this.modal = $(template);
    this.modal.find('.modal-dialog').addClass(this.size);
    if(this.id) {
      this.modal.attr('id', this.id);
    }
    this.addHiddenModal();

    return this;
  }
  /**
   * Retorna el modal
   * @return retorna el modal generado
   */
  getModal() {
    if(this.modal === null) {
      this.generateModal();
    }
    return this.modal;
  }

  /**
   * Setea el tamaño del modal, por defecto es md
   * @param {string} size - tamaño
   * @return retorna la instancia ModalBuilder
   */
  setSize(size) {
    if (size && size.indexOf('modal-') !== 0) {
      size = 'modal-' + size;
    }
    this.size = size ? size : 'modal-md';
    return this;
  }

  /**
   * Setea el id del modal
   * @param {string} id -
   * @return retorna la instancia ModalBuilder
   */
  setId(id) {
    this.id = id;
    return this;
  }

  /**
   * Setea el target donde se posicionara el modal
   * @param {string} target - Selector donde se insertara el modal
   * @return retorna la instancia ModalBuilder
   */
  setTarget(target) {
    this.target = target;
    return this;
  }

  /**
  * Ejecuta un get para obtener el modal
  * @param {string} url url a realizar la peticion
  * @param {Object} params parametros a enviar en la peticion get
  */
  loadFromRemote(url, params) {
    if(params === undefined || params === null) {
      params = {};
    }

    params.dataType = 'html';
    var self = this;

    $.get(url, params, function (html) {
      if (!self.modal) return;

      self.modal.find('> .modal-dialog').html(html);
      self.modal.trigger('galapagos:load-modal');
    }).fail((data, _response, kind) => {
      if (!self.modal) return;
      self.modal.html(`<div class='alert alert-danger'>Ocurrió un error al procesar esta accion: ${data.status} ${kind}</div>`);
    })
    ;
  }

  /**
   * Le agrega una funcion para remover la instancia del modal generado
   * @return retorna la instancia ModalBuilder
   */
  addHiddenModal() {
    var self = this;
    self.modal.on('hidden.bs.modal', function () {
      if (!self.modal) {
        return;
      }
      self.modal.remove();
      self.modal = null;
    });
    return this;
  }

  /**
   * Despliega el modal
   * @return retorna la instancia ModalBuilder
   */
  show() {
    $(this.target).append(this.getModal());
    this.getModal().modal({show: true});
    return this;
  }
}
