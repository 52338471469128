const onmount = require('onmount');
const $ = require('jquery');

class IterativeProgressComponent {
  constructor($root){
    this.$root = $root;
    this.$status = this.$root.find('.iterative-progress-status');
    this.$currentStatus = this.$root.find('.iterative-progress-current-status');
    this.$current = this.$root.find('.iterative-progress-current');
    this.$count = this.$root.find('.iterative-progress-count');
    this.endpoint = this.$root.data('url');
  }

  startProgress(){
    this.progress = setInterval(() => this.getProgress(), 2000);
  }

  stopProgress(){
    if(this.progress){
      clearInterval(this.progress);
    }
  }

  getProgress(){
    $.get(this.endpoint)
      .done((task) => this.updateProgress(task))
      .fail(() => this.stopProgress());
  }

  updateProgress(task){
    this.setStatus(task.status);
    this.$currentStatus.html(task.current_status);
    this.$current.html(task.current);
    this.$count.html(task.count);
    if(task.status === "completed" || task.status === "failed"){
      this.stopProgress();
    }
  }

  setStatus(status){
    switch(status){
      case "pending":
        this.stopStatusAnimation();
        this.setStatusColor("secondary");
        break;
      case "working":
        this.startStatusAnimation();
        this.setStatusColor("primary");
        break;
      case "failed":
        this.stopStatusAnimation();
        this.setStatusColor("danger");
        break;
      case "completed":
        this.stopStatusAnimation();
        this.setStatusColor("success");
        break;
    }
  }

  startStatusAnimation(){
    this.$status.addClass('fa-spin');
  }

  stopStatusAnimation(){
    this.$status.removeClass('fa-spin');
  }

  setStatusColor(color){
    this.$status.removeClass("text-secondary text-primary text-danger text-success").addClass(`text-${color}`);
  }
}

onmount('.tasks-iterative-progress-component', function(){
  const $root = $(this);
  $component = new IterativeProgressComponent($root);
  $component.startProgress();
});
