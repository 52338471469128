const onmount = require('onmount');
const $ = require('jquery');
/**
  Funciones que permiten a un select2
  transformarse en el siguiente input presente en el html
  Para que sea posible esto debe existir luego del form-group padre del select2
  otro form-group que contenga el input de texto a mostrar cuando se seleccione la opcion "other"
  del select. El form-group del input debe tener algun .input-group
  donde se posicionara el boton que permitira volver al select2
*/

function setupSelect2TextMode($selectFormGroup, $inputFormGroup){
  enableFormGroup($selectFormGroup);
  disableFormGroup($inputFormGroup);
  let backButton = $('<a/>');
  backButton.addClass('select2-text-mode-back btn position-absolute');
  backButton.css({'z-index': 1000, right: 0});
  backButton.on('click', function(){
    disableFormGroup($inputFormGroup);
    enableFormGroup($selectFormGroup);
    $selectFormGroup.find("select").val('').trigger('change');
  });
  backButton.html(`<i class="fas fa-times text-muted"></i>`);
  $inputFormGroup.find('.input-group').append(backButton);
}

function enableFormGroup($formGroup){
  $formGroup.show();
  $formGroup.find(':input').attr('disabled', false);
}

function disableFormGroup($formGroup){
  $formGroup.hide();
  $formGroup.find(":input").attr('disabled', true);
}

onmount('[data-provide="select2_text_mode"]', function(){
  let $input = $(this);
  let $inputFormGroup = $input.closest('.form-group');
  let $selectFormGroup = $inputFormGroup.prev('.form-group');
  let $select = $selectFormGroup.find("select");
  setupSelect2TextMode($selectFormGroup, $inputFormGroup);
  $select.on("change", function(e){
    if(e.target.value == "other"){
      enableFormGroup($inputFormGroup);
      disableFormGroup($selectFormGroup);
      $inputFormGroup.find(":input").focus();
    }
  });
});
