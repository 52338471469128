/**
* Espera que datatables haya sido creado
* @return {Promise}
*/
$.fn.waitDataTable = function waitDataTable() {
  return new Promise((resolve) => {
    if ($.fn.dataTable?.isDataTable(this)) {
      resolve();
    }
    else {
      this.on('preInit.dt', () => resolve());
    }
  });
};
