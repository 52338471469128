import onmount from 'onmount';
import Noty from 'noty';
import './notify_component.scss';

onmount('.noty_content.no_noty', function(){
  this.classList.remove('no_noty');
  const noty = new Noty({
    type: this.dataset.notyType,
    layout: 'topRight',
    text: this.outerHTML,
    timeout: this.dataset.notyTimeout,
    afterClose: function(){
      this.remove();
    }
  });
  noty.show();
});
