/** @file TODO: documentar */
import onmount from "onmount";
import VisibilityTracker from "./lib/visibility-tracker.js";
import load from "./lib/load";

const tracker = new VisibilityTracker((element) => {
  return load(element, element.getAttribute("data-render-async"));
});

onmount(
  "[data-render-async]",
  function () {
    tracker.watch(this);
  },
  function () {
    tracker.unwatch(this);
  }
);
