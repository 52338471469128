import Intercooler from 'intercooler';
import onmount from 'onmount';
const $ = require('jquery');

// Copiado de _MACROS en intercooler.js. Actualizar cuando eso cambie
const atributosIC = [
  'ic-get-from',
  'ic-post-to',
  'ic-put-to',
  'ic-patch-to',
  'ic-delete-from',
  'ic-style-src',
  'ic-attr-src',
  'ic-prepend-from',
  'ic-append-from',
  'ic-action',
].map(i => `[${i}]`).join(',');

let contentLoaded = false;
$('body').one('nodesProcessed.ic', () => contentLoaded = true);
onmount(atributosIC, function () {
  // Intercooler.processNodes no es siempre idempotente (no sabemos por que)
  // https://github.com/intercoolerjs/intercooler-js/issues/272
  if (contentLoaded && $(this).data('elementAdded.ic') !== true) {
    Intercooler.processNodes(this);
  }
});

onmount('[ic-trigger-on-load]', function () {
  const action = () => {
    if ($(this).attr('ic-trigger-on-load') !== 'false') {
      Intercooler.triggerRequest(this);
    }
  };
  if (contentLoaded) {
    action();
  }
  else {
    $('body').one('nodesProcessed.ic', action);
  }
});

// Cuando se procese un nodo de intercooler, se llama a onmount
// para ejecutar comportamiento en el dom agregado por IC
// Además, onmount ejecuta la funcion de salida para el dom que fue reemplazado por IC
$(document).on('nodesProcessed.ic', function(){
  onmount();
});
