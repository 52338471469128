const onmount = require("onmount");
const $ = require("jquery");

onmount("form.new_instance, form.edit_instance", function () {
  enableNonBillingExplanation();
  $("#instance_deployment_type, #instance_billable").on(
    "change",
    enableNonBillingExplanation
  );
});

function enableNonBillingExplanation() {
  const container = $(".form-group.instance_non_billing_explanation");
  if (
    $("#instance_deployment_type").val() == "normal" &&
    !$("#instance_billable").is(":checked")
  ) {
    container.show();
    container.find(":input").prop("disabled", false);
  } else {
    container.hide();
    container.find(":input").prop("disabled", true);
  }
}
