require("@rails/ujs").start();
require("turbolinks").start();
require("bootstrap");
require("cocoon-js");
require("../javascript/components");
require("../javascript/messages");
require("../javascript/select2")({
  theme: "bootstrap4",
  placeholder: "Seleccione...",
});
require("../javascript/select2_text_mode");
require("../javascript/datepicker");
require("../javascript/datetimepicker");
require("../javascript/intercooler");
require("../javascript/accordion_form");
require("../javascript/tax-number-mask");
require("../javascript/modals/modal-link");
require("../javascript/form-toggle");
require("../javascript/tooltip");
require("../javascript/popover");
require("../javascript/render-async");
require("./signature");
require("./custom_file");

// JS para páginas específicas
require("./pages/instances/form");

const onmount = require("onmount");
const $ = require("jquery");

$(document).on(
  "load turbolinks:load inserted.bs.popover buk:load-content cocoon:after-insert cocoon:after-remove",
  function () {
    onmount();
  }
);
$(document).on("turbolinks:before-cache", function () {
  onmount.teardown();
});
