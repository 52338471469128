const $ = require('jquery');
const onmount = require('onmount');
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min'
require('pc-bootstrap4-datetimepicker');

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'fas fa-clock',
    date: 'fas fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'fas fa-trash-alt',
    close: 'fas fa-times-circle'
  },
  locale: 'es'
});

onmount("[data-provide=datetimepicker]", function(){
  $(this).datetimepicker();
});
